import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { ImageIcon } from "lucide-react";

interface PlatformCardSkeletonProps {
    thumbnail?: boolean;
    title?: boolean;
    subtitle?: boolean;
}

export default function PlatformCardSkeleton({
    thumbnail = false,
    title = false,
    subtitle = false,
}: PlatformCardSkeletonProps) {
    return (
        <Card className="relative shadow-md flex flex-col">
            {thumbnail && (
                <div className="animate-pulse bg-slate-100 relative aspect-video rounded-t-md border-b flex items-center justify-center">
                    <ImageIcon className="w-12 h-12 text-muted-foreground" />
                </div>
            )}

            {(title || subtitle) && (
                <footer className="flex flex-col gap-2 px-3 py-4">
                    {title && (
                        <Skeleton className="h-5 w-3/4 rounded bg-slate-100" />
                    )}
                    {subtitle && (
                        <Skeleton className="h-4 w-1/2 rounded bg-slate-100" />
                    )}
                </footer>
            )}
        </Card>
    );
}

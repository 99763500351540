import { GetCollectionsResponseType } from "@/app/(platform)/storage/hooks/useGetCollections";
import { GetFilesResponseType } from "@/app/(platform)/storage/hooks/useGetFiles";
import { StorageFilterByType } from "@/app/(platform)/storage/types";
import { create } from "zustand";

export type StorageExplorerOptions = {
    description?: {
        keyName: string;
        defaultValue: string;
    };
    collections?: {
        onCollectionClick?: (
            coll: GetCollectionsResponseType["data"][0]
        ) => void;
    };
    files?: {
        onFileClick?: (file: GetFilesResponseType["data"][0]) => void;
        show: StorageFilterByType;
    };
};
export type VideoOptions = {
    videoId: string;
    libraryId: number;
    uid: string;
};
export type PresentationOptions = {
    presentationId: string;
    libraryId: number;
};

interface StorageStore {
    explorerOpen: boolean;
    setExplorerOpen: (open: boolean, options?: StorageExplorerOptions) => void;
    explorerOptions: StorageExplorerOptions;

    videoSheetOpen: boolean;
    setVideoSheetOpen: (open: boolean, options?: VideoOptions) => void;
    videoOptions: VideoOptions;

    presentationSheetOpen: boolean;
    setPresentationSheetOpen: (
        open: boolean,
        options?: PresentationOptions
    ) => void;
    presentationOptions: PresentationOptions;
}

export const useStorage = create<StorageStore>((set, get) => ({
    explorerOpen: false,
    setExplorerOpen: (open: boolean, options?: StorageExplorerOptions) => {
        set({
            explorerOpen: open,
            ...(options && { explorerOptions: options }),
        });
    },
    explorerOptions: {
        description: undefined,
        collections: {
            onCollectionClick: undefined,
        },
        files: {
            onFileClick: undefined,
            show: "all",
        },
    },

    videoSheetOpen: false,
    setVideoSheetOpen: (open: boolean, options?: VideoOptions) => {
        set({
            videoSheetOpen: open,
            ...(options && { videoOptions: options }),
        });
    },
    videoOptions: {
        videoId: "",
        libraryId: 0,
        uid: "",
    },

    presentationSheetOpen: false,
    setPresentationSheetOpen: (
        open: boolean,
        options?: PresentationOptions
    ) => {
        set({
            presentationSheetOpen: open,
            ...(options && { presentationOptions: options }),
        });
    },
    presentationOptions: {
        presentationId: "",
        libraryId: 0,
    },
}));

"use client";

import { useCallback, useEffect, useState } from "react";
import {
    StorageFilterByType,
    storageFilterByTypeOptions,
    StorageOrderBy,
    storageOrderByOptions,
    StorageSortBy,
    storageSortByOptions,
} from "../../types";
import { ChevronDown, ChevronLeft, ChevronRight, Search } from "lucide-react";
import { T, useTranslate } from "@tolgee/react";
import PlatformCard from "@/app/(platform)/sharedComponents/platformCard";
import { Input } from "@/components/ui/input";
import debounce from "lodash.debounce";
import { Button, buttonVariants } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import PlatformCardSkeleton from "@/app/(platform)/sharedComponents/platformCardSkeleton";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { cn, formatIsoStringDate, formatSecondsToMinutes } from "@/lib/utils";
import { GetFilesResponseType, useGetFiles } from "../../hooks/useGetFiles";

interface FilesGridProps {
    header: {
        title?: {
            keyName: string;
            defaultValue: string;
        };
        options: {
            showSeeAllLink?: boolean;
            allowSorting?: boolean;
            allowSearch?: boolean;
            allowFilterByType?: boolean;
            initialFilterByType?: StorageFilterByType;
        };
    };
    grid: {
        collectionId?: string;
        resultsPerPage: number;
        setIsNoResults?: (val: boolean) => void;
        itemOnClick?: (file: GetFilesResponseType["data"][0]) => void;
    };
    footer: {
        showPagination?: boolean;
        allowChangingResultsPerPage?: boolean;
        justifyContent?: "justify-start" | "justify-end";
    };
}

export default function FilesGrid({ header, grid, footer }: FilesGridProps) {
    const [search, setSearch] = useState("");
    const [filterByType, setFilterByType] = useState<StorageFilterByType>(
        header.options.initialFilterByType || "all"
    );
    const [sortBy, setSortBy] = useState<StorageSortBy>("createdAt");
    const [orderBy, setOrderBy] = useState<StorageOrderBy>("desc");
    const [resultsPerPage, setResultsPerPage] = useState(grid.resultsPerPage);
    const [page, setPage] = useState(1);

    // prevent notifying on no results after user searched
    const [wereResults, setWereResults] = useState(false);

    const { data, isPending } = useGetFiles(
        page,
        resultsPerPage,
        search,
        sortBy,
        orderBy,
        filterByType,
        grid.collectionId
    );

    useEffect(() => {
        if (data?.data.length) {
            setWereResults(true);
        }
    }, [data]);
    useEffect(() => {
        if (!isPending && !wereResults) {
            grid?.setIsNoResults?.(!data?.data?.length);
        }
    }, [data?.data?.length, grid, isPending, wereResults]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(
        debounce((text: string) => {
            setPage(1);
            setSearch(text);
        }, 800),
        []
    );

    const { t } = useTranslate();

    const sortByOption = storageSortByOptions.find((o) => o.value === sortBy);
    const orderByOption = storageOrderByOptions.find(
        (o) => o.value === orderBy
    );
    const filterByTypeOption = storageFilterByTypeOptions.find(
        (o) => o.value === filterByType
    );

    const numOfSkeletons = Math.min(12, resultsPerPage);

    const resultsPerPageOptions = [8, 16, 24];

    const totalNumberOfResults = data?.total || 0;
    const totalPages = Math.ceil(totalNumberOfResults / resultsPerPage);

    const allowPrev = page > 1;
    const allowNext = page < totalPages;

    return (
        <div>
            {(isPending || !!data?.data?.length) && (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-4">
                        {header.title && (
                            <h3 className=" text-xl font-semibold text-muted-foreground">
                                <T
                                    keyName={header.title.keyName}
                                    defaultValue={header.title.defaultValue}
                                />
                            </h3>
                        )}
                        {header.options.allowSearch && (
                            <div className="relative rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center ps-2">
                                    <Search className="size-4 text-gray-400" />
                                </div>
                                <Input
                                    placeholder="חיפוש..."
                                    placeholderKeyName="storage.collections-grid.search-inputs.placeholder"
                                    defaultValue=""
                                    onChange={(e) =>
                                        debounceSearch(e.target.value)
                                    }
                                    className="max-w-[150px] sm:max-w-[220px] h-8 text-sm ps-8"
                                />
                            </div>
                        )}
                    </div>
                    {!isPending && (
                        <div className="flex flex-row gap-2">
                            {header.options.allowFilterByType && (
                                <DropdownMenu dir="rtl">
                                    <DropdownMenuTrigger asChild>
                                        <Button
                                            className="gap-1 font-normal text-sm"
                                            variant="outline"
                                            size="sm"
                                        >
                                            <T
                                                keyName="storage.files-grid.filter-by-type-btn"
                                                defaultValue="סוג"
                                            />
                                            :{" "}
                                            {t(
                                                filterByTypeOption!.keyName,
                                                filterByTypeOption!.defaultValue
                                            )}
                                            <ChevronDown className="size-4" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent
                                        side="bottom"
                                        align="end"
                                        className="w-40"
                                    >
                                        <DropdownMenuRadioGroup
                                            value={filterByType}
                                            onValueChange={(value: string) => {
                                                setFilterByType(
                                                    value as StorageFilterByType
                                                );
                                                setPage(1);
                                            }}
                                        >
                                            {storageFilterByTypeOptions.map(
                                                (option) => (
                                                    <DropdownMenuRadioItem
                                                        key={option.value}
                                                        value={option.value}
                                                        className="text-sm"
                                                    >
                                                        <T
                                                            keyName={
                                                                option.keyName
                                                            }
                                                            defaultValue={
                                                                option.defaultValue
                                                            }
                                                        />
                                                    </DropdownMenuRadioItem>
                                                )
                                            )}
                                        </DropdownMenuRadioGroup>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            )}
                            {header.options.allowSorting && (
                                <>
                                    <DropdownMenu dir="rtl">
                                        <DropdownMenuTrigger asChild>
                                            <Button
                                                className="gap-1 font-normal text-sm"
                                                variant="outline"
                                                size="sm"
                                            >
                                                <T
                                                    keyName="storage.collections-grid.sort-btn"
                                                    defaultValue="מיון לפי"
                                                />
                                                :{" "}
                                                {t(
                                                    sortByOption!.keyName,
                                                    sortByOption!.defaultValue
                                                )}
                                                <ChevronDown className="size-4" />
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                            side="bottom"
                                            align="end"
                                            className="w-40"
                                        >
                                            <DropdownMenuRadioGroup
                                                value={sortBy}
                                                onValueChange={(
                                                    value: string
                                                ) => {
                                                    setSortBy(
                                                        value as StorageSortBy
                                                    );
                                                    setPage(1);
                                                }}
                                            >
                                                {storageSortByOptions.map(
                                                    (option) => (
                                                        <DropdownMenuRadioItem
                                                            key={option.value}
                                                            value={option.value}
                                                            className="text-sm"
                                                        >
                                                            <T
                                                                keyName={
                                                                    option.keyName
                                                                }
                                                                defaultValue={
                                                                    option.defaultValue
                                                                }
                                                            />
                                                        </DropdownMenuRadioItem>
                                                    )
                                                )}
                                            </DropdownMenuRadioGroup>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                    <DropdownMenu dir="rtl">
                                        <DropdownMenuTrigger asChild>
                                            <Button
                                                className="gap-1 font-normal text-sm"
                                                variant="outline"
                                                size="sm"
                                            >
                                                <T
                                                    keyName="storage.collections-grid.order-btn"
                                                    defaultValue="סדר"
                                                />
                                                :{" "}
                                                {t(
                                                    orderByOption!.keyName,
                                                    orderByOption!.defaultValue
                                                )}
                                                <ChevronDown className="size-4" />
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                            side="bottom"
                                            align="end"
                                        >
                                            <DropdownMenuRadioGroup
                                                value={orderBy}
                                                onValueChange={(
                                                    value: string
                                                ) => {
                                                    setOrderBy(
                                                        value as StorageOrderBy
                                                    );
                                                    setPage(1);
                                                }}
                                            >
                                                {storageOrderByOptions.map(
                                                    (option) => (
                                                        <DropdownMenuRadioItem
                                                            key={option.value}
                                                            value={option.value}
                                                            className="text-sm"
                                                        >
                                                            <T
                                                                keyName={
                                                                    option.keyName
                                                                }
                                                                defaultValue={
                                                                    option.defaultValue
                                                                }
                                                            />
                                                        </DropdownMenuRadioItem>
                                                    )
                                                )}
                                            </DropdownMenuRadioGroup>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </>
                            )}
                            {header.options.showSeeAllLink && (
                                <a
                                    href="/storage?tab=files"
                                    className={cn(
                                        buttonVariants({
                                            variant: "outline",
                                            size: "sm",
                                        }),
                                        "gap-1 font-normal text-sm"
                                    )}
                                >
                                    <T
                                        keyName="storage.files-grid.see-all-btn"
                                        defaultValue="לכל הקבצים"
                                    />
                                    <ChevronLeft className="size-4" />
                                </a>
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className="mt-4 gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {isPending
                    ? Array.from({ length: numOfSkeletons }, (_, i) => i).map(
                          (_, idx) => (
                              <PlatformCardSkeleton
                                  key={idx}
                                  title={true}
                                  subtitle={true}
                                  thumbnail={true}
                              />
                          )
                      )
                    : data?.data.map((file) => {
                          return (
                              <PlatformCard
                                  key={file.id}
                                  onClick={() => grid.itemOnClick?.(file)}
                                  thumbnail={{
                                      src: file.thumbnailUrl,
                                  }}
                                  thumbnailBadge={{
                                      text:
                                          file.type === "video" ? (
                                              formatSecondsToMinutes(
                                                  file.detailForBadge
                                              )
                                          ) : file.detailForBadge <= 1 ? (
                                              <T
                                                  keyName="storage.presentation-card.num-of-slides-badge.single"
                                                  defaultValue="שקופית אחת"
                                              />
                                          ) : (
                                              <T
                                                  keyName="storage.presentation-card.num-of-slides-badge.multiple"
                                                  defaultValue={`${file.detailForBadge} שקופיות`}
                                                  params={{
                                                      numOfSlides:
                                                          file.detailForBadge,
                                                  }}
                                              />
                                          ),
                                  }}
                                  text={{
                                      title: file.name || (
                                          <T
                                              keyName="storage.no-name"
                                              defaultValue="ללא שם"
                                          />
                                      ),
                                      subtitle: (
                                          <>
                                              {file.type === "video" ? (
                                                  <T
                                                      keyName="storage.video"
                                                      defaultValue="סרטון"
                                                  />
                                              ) : (
                                                  <T
                                                      keyName="storage.presentation"
                                                      defaultValue="מצגת"
                                                  />
                                              )}{" "}
                                              · {formatIsoStringDate(file.date)}
                                          </>
                                      ),
                                  }}
                              />
                          );
                      })}
            </div>
            {!isPending && !!data?.data?.length && footer.showPagination && (
                <div
                    className={cn(
                        "mt-6 flex flex-row gap-6 items-center",
                        footer.justifyContent || ""
                    )}
                >
                    {footer.allowChangingResultsPerPage && (
                        <div className="flex flex-row gap-1 items-center text-sm">
                            <T
                                keyName="storage.collections-grid.pagination.results-per-page"
                                defaultValue="תוצאות בעמוד:"
                            />
                            <Select
                                dir="rtl"
                                value={resultsPerPage.toString()}
                                onValueChange={(value: string) => {
                                    setPage(1);
                                    setResultsPerPage(parseInt(value));
                                }}
                            >
                                <SelectTrigger className="w-fit text-sm h-9">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    {resultsPerPageOptions.map((val) => (
                                        <SelectItem
                                            className="text-sm"
                                            key={val}
                                            value={val.toString()}
                                        >
                                            {val}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    )}
                    <p className="text-sm">
                        <T
                            keyName="storage.collections-grid.pagination.page-out-of-pages"
                            defaultValue="עמוד {currentPage} מתוך {totalPages}"
                            params={{
                                currentPage: page,
                                totalPages,
                            }}
                        />
                    </p>
                    <div className="flex flex-row gap-2">
                        <Button
                            className="flex flex-row items-center font-normal text-sm"
                            variant="outline"
                            size="sm"
                            disabled={!allowPrev}
                            onClick={() => {
                                setPage((prev) => prev - 1);
                            }}
                        >
                            <ChevronRight className="size-4" />
                            <T
                                keyName="storage.collections-grid.pagination.prev"
                                defaultValue="הקודם"
                            />
                        </Button>
                        <Button
                            className="flex flex-row items-center font-normal text-sm"
                            variant="outline"
                            size="sm"
                            disabled={!allowNext}
                            onClick={() => {
                                setPage((prev) => prev + 1);
                            }}
                        >
                            <T
                                keyName="storage.collections-grid.pagination.next"
                                defaultValue="הבא"
                            />
                            <ChevronLeft className="size-4" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

import { useQuery } from "@tanstack/react-query";
import { client } from "@/lib/hono";
import { InferResponseType } from "hono";
import { StorageFilterByType, StorageOrderBy, StorageSortBy } from "../types";

export type GetFilesResponseType = InferResponseType<
    typeof client.api.storage.query.files.$get,
    200
>;

export const useGetFiles = (
    page: number,
    resultsPerPage: number,
    search: string,
    sortBy: StorageSortBy,
    orderBy: StorageOrderBy,
    filterByType: StorageFilterByType,
    collectionId?: string
) => {
    const query = useQuery({
        queryKey: [
            "get-storage-files",
            {
                page,
                resultsPerPage,
                search,
                sortBy,
                orderBy,
                filterByType,
                collectionId,
            },
        ],
        queryFn: async () => {
            const response = await client.api.storage.query.files.$get({
                query: {
                    page: page.toString(),
                    resultsPerPage: resultsPerPage.toString(),
                    search,
                    sortBy,
                    orderBy,
                    filterByType,
                    collectionId,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch files");
            }

            const { data, total } = await response.json();
            return { data, total };
        },
    });

    return query;
};
